
import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from './button/Button';


export default function HeroSection({ miniScreen })  {

  return (
    <div className='flex justify-center'>
      <div className='2xl:w-[1400px] 3xl:w-[1500px] md:mx-[6%]'>

        <Card sx={{ display: 'flex',height:`${miniScreen ? 'auto' : "600px"}`, backgroundColor: '#000000', width: '100%', borderRadius:"20px", marginTop:"85px", boxShadow: '0px 4.361111164093018px 92.67361450195312px 26.166667938232422px #00000033'}}>
        <div className='hero-section w-full lg:z-20 md:pl-12 pb-5 lg:pb-0'>
            <Box  sx={{ display: 'flex', flexDirection: 'column', marginTop: `${ miniScreen ? '20px' : '60px'}`}}>
            <CardContent sx={{ flex: '1 0 auto' }}>
              <Typography component="div" textAlign={miniScreen ? 'center' : 'left'} sx={{fontWeight: 800, color:"white",fontSize:`${miniScreen ? '30px' : '50px'}` , marginRight:`${miniScreen ? '' : '-700px'}` }}>
                Safe and Secure, from {!miniScreen && <br></br>}
                log<span className='relative'><img src="images/login-icon.png" alt="" className='h-5 md:h-10 md:z-30 inline absolute -top-[9px] md:-top-[16px]' /><span>ı</span></span>n to logout
              </Typography>
              <Typography variant="subtitle1" component="div" textAlign={miniScreen ? 'center' : 'left'} sx={{color:"white",fontSize:`${miniScreen ? '15px' : '22px'}`, marginY: `${miniScreen ? '25px' : ''}`}}>
                Hire Cyber Security and Banking IT Professionals
              </Typography>

              <ul class="list-disc text-white pl-5 md:py-8">
                <li>Consulting Services</li>
                <li>Technology & Business Strategy Development</li>
                <li>Enterprise & Solution Architecture</li>
                <li>Products & Solutions</li>
                <li>Staff Augmentation</li>
              </ul>

              <div className='w-full md:w-fit pt-7 md:pt-0'>
                <Button value='Get Started' />
              </div>
            </CardContent>
          </Box>
        </div>
          <img src="images/image2.png" alt="" className='h-full w-2/4 object-fit z-10 bg-black hidden lg:inline-block -pl-2' />
        </Card>
      </div>
      
    </div>
  );
}