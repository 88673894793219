import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';

export default function ActionCard({ card_image, title,tech_stack,backgroundColor, company, tech_icon }) {
  return (
    <Card sx={{ maxWidth: 370.87,borderRadius:"20px",marginTop:"30px" }}>
      <CardActionArea>
        <CardMedia
         sx={{
          backgroundColor:{backgroundColor},
         }}
          component="img"
          height="140"
          image={card_image}
          alt="green iguana"
        />
        <CardContent sx={{backgroundColor: 'rgba(255, 255, 255, 0.9)'}}>

        <p className='font-semibold'>
          {title}
        </p>
  
        <p className='flex gap-1'>
          <img src={tech_icon} alt={tech_icon} className="object-contain" />
          {tech_stack}
        </p>
        <p className='text-xs text-[#00000099]'>
          worked with
        </p>

         <img src={company} alt={company} className='mt-1' />
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
