
import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';


export default function ContainerCard({card_image, title, content, designation, margin = "0"})  {

  return (
    <div className='flex text-white box-border section7-card py-6 px-4 relative'>
        <img src="images/dot.png" alt="" className='absolute -top-3 left-28 z-30'/>
        <div class="flex flex-col gap-4 h-[100%]">
                <div
                        style={{
                        width: "79.02px",
                        height: "79.02px",
                        marginTop:"20px",
                        marginLeft:"25px",
                        height: '10%'
                        }}
                ><img src={card_image}/></div> 

                <div className='flex-1'
                        style={{color:"white",
                        fontFamily: 'Poppins',
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: "21px",
                        lineHeight: "123%",
                        height: '70%'
                        }}  
                
                >{content}</div>

                <div className='h-[20%]'>

                        <div
                                style={{color:"white",
                                fontFamily: 'Poppins',
                                fontStyle: "normal",
                                fontWeight: 600,
                                fontSize: "21px",
                                lineHeight: "123%",
                                }} 
                        >{title}</div>

                        <div 
                                style={{color:"white",
                                fontFamily: 'Popins',
                                fontStyle: "normal",
                                fontWeight: 400,
                                fontSize: "21px",
                                lineHeight: "123%"}}>

                                {designation}
                        </div>
                </div>

        </div>
    </div>


  );
}