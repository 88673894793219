import * as React from 'react';
import Box from '@mui/material/Box';
import ContainerCard from './containers/ContainerCard';
import Carousel from 'react-material-ui-carousel';

export default function Container4({ miniScreen })  {

  return (
    <div className='md:mx-[3.5%] pb-20 flex justify-center'>
      <div className='2xl:w-[1500px] 3xl:w-[1600px]'>
        <Box className="selection7" sx={{ display: 'flex',backgroundColor:"#232223", borderRadius:"20px", boxShadow: '0px 4.361111164093018px 92.67361450195312px 26.166667938232422px #00000033'}}>
          <Box  sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', zIndex:1, marginTop: '80px'}}>

            <p className='text-center mr-5 font-bold text-4xl md:text-5xl lg:text-6xl mb-16'><span className='section7-card-heading'>WHA</span><span className='text-[#D2C0E4]'>THECLIENTS</span><span className='section7-card-heading-last'>AY</span></p>

              <div className="hidden md:grid col-span-12 md:grid-cols-12 gap-10 pb-32 pt-10 px-28">
                <div className='col-span-4'>
                  <ContainerCard
                  card_image={"images/Ellipse3.png"}
                  title={"Justin Kathan"}
                  designation={"Co-founder of Joist"}
                  margin= "0"
                  content="
                      “I have been a part of the fintech
                        community and have attended 2
                        micro-events so far and I must say
                        this is by far the best group of 
                        people I have come across within
                        the fintech community”. "
                />
                </div>

                <div className='col-span-4'>
                  <ContainerCard
                  card_image={"images/Ellipse2.png"}
                title={"Jaydon Bator"}
                designation={"CTO of Eco Focus"}
                margin= "3"
                content="
                      “I have seen Gaper working on
                      events in the fintech and startup
                      space and I am super excited
                      for the fintech summit” "
                  />
                </div>

                <div className='col-span-4'>

                  <ContainerCard
                  card_image={"images/Ellipse.png"}
                  title={"Diana Enrique"}
                  designation={"COO at Strat Security"}
                  margin= "2"
                  content="
                      “The world has truly gone digital
                      and I am glad businesses and 
                      startups are now realizing the
                      power of fintech. Excited to see
                      such big events unfold on the
                      fintech space” "
                  />
                </div>

              </div>

              <div className='md:hidden block'>
                <Carousel
                  animation={'slide'}
                  navButtonsAlwaysInvisible={true}
                  sx={{overflow: 'visible', position: 'static', width: '100vw', height: '500px'}}
                >
                    <ContainerCard
                    card_image={"images/Ellipse3.png"}
                    title={"Justin Kathan"}
                    designation={"Co-founder of Joist"}
                    content="
                        “I have been a part of the fintech
                          community and have attended 2
                          micro-events so far and I must say
                          this is by far the best group of 
                          people I have come across within
                          the fintech community”. "
                  />
                  
                    <ContainerCard
                    card_image={"images/Ellipse2.png"}
                    title={"Jaydon Bator"}
                    designation={"CTO of Eco Focus"}
                    margin= "3"
                    content="
                          “I have seen Gaper working on
                          events in the fintech and startup
                          space and I am super excited
                          for the fintech summit” "
                  />
                
                    <ContainerCard
                    card_image={"images/Ellipse.png"}
                    title={"Diana Enrique"}
                    designation={"COO at Strat Security"}
                    margin= "2"
                    content="
                        “The world has truly gone digital
                        and I am glad businesses and 
                        startups are now realizing the
                        power of fintech. Excited to see
                        such big events unfold on the
                        fintech space” "
                  />
                </Carousel>
              </div>

          </Box>

        </Box>
      </div>
    </div>
  );
}