import React from 'react'
import section5_bg from './section5-bg.png';

function Section5({ miniScreen }) {
    return (
        <div className={`py-24 px-3 md:px-[3.5%]`} style={{ backgroundImage: `url(${section5_bg})`, backgroundPositionX: `${miniScreen ? '' : '-5%'}`, backgroundRepeat: 'no-repeat' }}>
           <div className='flex justify-center'>

                <div className='2xl:w-[1500px] 3xl:w-[1600px] flex flex-col md:grid grid-cols-12 '>
                    <div className="col-span-6">
                        <img className='rounded-md' src="images/Cojiant.png" alt="" />
                    </div>
                    <div></div>
                    <div className="md:col-span-5 mt-26 md:mt-10 flex flex-col gap-8">
                        <p className='text-3xl md:text-5xl md:leading-[50px] font-extrabold'>
                            Security Experts<br></br>
                            <span className='text-gradient'>That can protect</span><br></br>
                            your business<br></br>
                        </p>
                        <p className='font-medium text-lg'>
                            Unify your cybersecurity program with cyber security<br></br>
                            program with top 1% cyber security and banking experts<br></br>
                            from all over the world through Gapicorn platform.
                        </p>
                        <div className='bg-[#8066FF] text-white text-center font-semibold py-3 px-8 w-fit rounded-lg cursor-pointer'>Get Started</div>
                    </div>
                </div>
           </div>
        </div>
    )
}

export default Section5