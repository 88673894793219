import React from 'react';
import section6_bg from './section6-bg.png'

function Section6({ miniScreen }) {
    return (
        <div style={{ backgroundImage: `url(${section6_bg})` }}>
            <div className='grid grid-cols-4 relative'>
                <div className='col-span-4 md:col-span-2 flex flex-col gap-4 px-5 py-8 md:py-16 md:pl-20 text-white'>
                    <div className='text-5xl xl:text-6xl font-black'>Skyrocket your growth with Gapicorn experts</div>
                    <p className=''>Want consultations? We believe that existing systems can be redefined to perform exceptionally well with the right direction and processes; </p>
                    <div className='flex items-center gap-5 cursor-pointer px-5 py-2 w-fit bg-[#8066FF] rounded-xl mt-5'>
                        Book a call today
                        <img src="images/arrow-white.png" alt="" className='h-3' />
                    </div>
                </div>
                {/* <div className='hidden md:block col-span-1 absolute right-20 bottom-0'>
                    <img src="images/rocket.png" className="" />
                </div> */}
            </div>
        </div>
    )
}

export default Section6