import React from 'react';
import { Box, Typography, Button, Divider } from '@mui/material';

function Section3() {
    return (
        <div className='flex justify-center'>

            <div className='2xl:w-[1400px] 3xl:w-[1500px] my-20 md:my-40 flex items-center flex-col md:grid md:grid-cols-12 space-x-4 md:space-x-10'>
                <div className='col-span-6 '>
                    <p className='font-semibold text-3xl md:text-5xl lg:text-6xl leading-[40px] lg:leading-[60px]'>
                        Effortless Cyber<br></br>
                        Security and <br />
                        Fintech<br></br>
                        Talent Sourcing
                    </p>
                    <div className='flex justify-center w-160 gap-2 font-bold whitespace-nowrap border border-[#8066FF] text-[#8066FF] rounded-[5px] py-2 cursor-pointer mt-12 md:mt-8' >
                        Get Started
                        <img src="images/arrow-right.svg" alt="" />
                    </div>
                </div>
                <div className='col-span-6 flex flex-col gap-2 max-w-lg mt-7 md:mt-0'>
                    <div className='flex justify-between gap-2'>
                        <img src="images/list.png" alt="" className='bg-[#8066FF] p-3 h-11 2xl:p-4 2xl:h-12 mt-1.5' />
                        <div>
                            <p className='font-medium text-[#8066FF] text-base 1xl:text-lg'>STEP 1</p>
                            <p className='font-medium text-xl'>Define your needs</p>
                            <p>Outline the specific skills, experience and qualifications you require.</p>
                        </div>
                    </div>
                    <div className='h-16 w-0.5 bg-[#8066FF] ml-[20px] -mt-10'></div>
                    <div className='flex justify-between gap-2'>
                        <img src="images/monitor.png" alt="" className='bg-[#8066FF] p-3 h-11 2xl:p-4 2xl:h-12 mt-1.5' />
                        <div>
                            <p className='font-medium text-[#8066FF] text-base 1xl:text-lg'>STEP 2</p>
                            <p className='font-medium text-xl'>Talk to our experts</p>
                            <p>Speak with our team to discuss your needs and the best approach to finding the right talent.</p>
                        </div>
                    </div>
                    <div className='h-16 w-0.5 bg-[#8066FF] ml-[20px] -mt-10'></div>
                    <div className='flex justify-between gap-2'>
                        <img src="images/star.png" alt="" className='bg-[#8066FF] p-3 h-11 2xl:p-4 2xl:h-12 mt-1.5' />
                        <div>
                            <p className='font-medium text-[#8066FF] text-base 1xl:text-lg'>STEP 3</p>
                            <p className='font-medium text-xl'>Get matched with top talent</p>
                            <p>Our team will present you with a selection of highly-skilled and experienced candidates that meet your requirements.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Section3