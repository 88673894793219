import React from 'react'

function Footer() {
  return (
    <div className='bg-black text-white text-center py-16'>
        {/* Icons */}
        <div className='flex justify-center gap-10'>
            <img src="images/youtube-icon.png" alt="youtube icon" className='h-5 cursor-pointer' />
            <img src="images/linkedin-icon.png" alt="linkedin icon" className='h-5 cursor-pointer' />
            <img src="images/facebook-icon.png" alt="facebook icon" className='h-5 cursor-pointer' />
            <img src="images/insta-icon.png" alt="Instagram icon" className='h-5 cursor-pointer' />
        </div>
        {/* Navigations */}
        <div className='flex md:flex-row flex-col justify-center items-center md:gap-24 gap-5 md:my-8 xl:my-12 2x:my-14 my-12'>
            <div className='text-xl 2xl:text-[32px] font-semibold cursor-pointer'>Home</div>
            <div className='text-xl 2xl:text-[32px] font-semibold cursor-pointer'>Application</div>
            <img src="images/gapicorn-logo-white.png" alt="Gapicorn logo" className='h-16 cursor-pointer' />
            <div className='text-xl 2xl:text-[32px] font-semibold cursor-pointer'>Blogs</div>
            <div className='text-xl 2xl:text-[32px] font-semibold cursor-pointer'>Podcast</div>
        </div>
        {/* Copyright */}
        <div className='text-sm md:text-base 2xl:text-xl'>Copyright 2022 gapicorn</div>
    </div>
  )
}

export default Footer