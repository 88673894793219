
import * as React from 'react';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import ActionCard from './ActionCard';

export default function Section4({ miniScreen }) {


  return (
    <div className='flex justify-center'>
      <Box className='2xl:w-[1450px] 3xl:w-[1600px] md:h-[700px] 2xl:h-[630px]' sx={{ display: 'flex', backgroundColor: "#8066FF", borderRadius: "30px", marginY: `${ miniScreen ? "10px" : "70px"}` }}>
        <div className='flex flex-col md:grid grid-cols-11 gap-x-7 overflow-hidden px-8' >
          <CardContent className='col-span-7 text-center md:text-left relative -ml-4' >
            <p className='text-white capitalize text-4xl md:text-5xl font-semibold 2xl:text-5xl font-[poppins] pt-8 '>
              Building better Cyber {!miniScreen && <br></br>}
              Security Networks, {!miniScreen && <br></br>}
              through out the globe<br></br>
            </p>
            <Typography variant="subtitle1" component="div"
              sx={{
                color: "white", marginTop: "20px",
                display: "flex",
                fontFamily: 'Poppins',
                fontStyle: "normal",
                fontWeight: 300,
                fontSize: `${miniScreen ? '20px' : "25.0877px"}`,
                lineHeight: `${miniScreen ? "inherit" : "36px"}`,
                paddingTop: "5px",
                letterSpacing: "-0.01em",
              }}>

              Expertly curated software development teams, {!miniScreen && <br></br>}
              handpicked to match your project needs and {!miniScreen && <br></br>}
              company culture.
            </Typography>

            <div className='hidden md:flex justify-center items-center gap-2 font-bold border border-[#8066FF] w-full md:w-44 text-[#1536BE] bg-white rounded-lg py-2 px-3 mt-8 cursor-pointer' >
              Get Started
              <img src="images/arrow-right.png" alt="" />
            </div>

            <div className='absolute bottom-5 hidden md:block'>
              <p className='text-white'>Trusted By</p>
              <div className='flex gap-8'>
                <img src="images/lynx-white.png" alt="" className='' />
                <img src="images/wechat.png" alt="" className='' />
                <img src="images/ultralinx-white.png" alt="" className='' />
              </div>
            </div>
          </CardContent>

          <div className='col-span-2 relative'>
            <div className='md:pt-4'>
              <ActionCard
                card_image="images/smiling-modern-girl-using-mobile-phone.png"
                title={"Rebecca Sorose"}
                tech_icon={'images/person.png'}
                tech_stack={"Cyber Security Expert"}
                backgroundColor={"#A9C1FD"}
                company={'images/ola.png'}
              />
            </div>
            <div className='md:absolute md:-bottom-12'>
              <ActionCard
                card_image="images/life-style-people-emotion.png"
                title={"Alex Parker"}
                tech_icon={'images/pen.png'}
                tech_stack={"Network Architect"}
                backgroundColor={"#808080"}
                company={'images/lynx.png'}
              />
            </div>
          </div>

          <div className='col-span-2 md:mt-8 relative pb-6 md:pb-0'>
            <div className='md:pt-6'>
              <ActionCard
                card_image="images/man-image.png"
                title={"Peter Henson"}
                tech_icon={'images/mobile.png'}
                tech_stack={"Fintech Security Expert"}
                company={'images/ultralinx.png'}
                backgroundColor={"#A9C1FD"}
              />
            </div>
            <div className='md:absolute md:-bottom-24'>
              <ActionCard
                card_image="images/rectangle.png"
                title={"Alissa Hartman"}
                tech_icon={'images/person.png'}
                tech_stack={"Cyber Security Expert"}
                company={'images/ola.png'}
                backgroundColor={"#FFCAA5"}
              />
            </div>
          </div>
        </div>
      </Box>
    </div>
  );
}