import Button from "./button/Button";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { useEffect, useState } from "react";

export default function Navbar({ miniScreen }) {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="flex justify-center">
            <div class="2xl:w-[1500px] 3xl:w-[1650px] container mx-auto px-9 mt-26">
                <nav className="flex relative z-20 items-center justify-between">
                    <img className='h-[36px] cursor-pointer' src="images/gapicorn.png" />

                    <div onClick={() => setIsOpen(!isOpen)} className="block lg:hidden cursor-pointer z-30">
                        {isOpen ? <AiOutlineClose size={26} /> : <AiOutlineMenu size={26} />}
                    </div>

                    <div className={`absolute ${isOpen ? 'flex w-full md:w-2/4 top-68 right-0 z-40 border border-x-gray-200 lg:border-none rounded-md px-[24px] py-26' : 'hidden -right-full top-68'} lg:flex flex-col lg:static lg:flex-row justify-center gap-5 transition-all lg:gap-10`}>
                        {['Overview', 'Key features', 'Ride away', 'Reviews']?.map(item => <p className="z-40 font-medium whitespace-nowrap">{item}</p>)}
                        <Button className='w-160 whitespace-nowrap lg:hidden block' value="Get Started" />
                    </div>

                    <Button className='w-160 whitespace-nowrap hidden lg:inline-block' value="Get Started" />
                </nav>
            </div>
        </div>
    );
}