import React, { useState, useEffect } from "react";
import Navbar from "./components/Navbar";
import HeroSection from "./components/HeroSection";
import Section4 from "./components/Section4";
import Box from '@mui/material/Box';
import { Container } from "@mui/system";
import Footer from "./components/Footer";
import Section3 from "./components/Section3";
import Section5 from "./components/Section5";
import Section7 from "./components/section7";
import Container5 from "./components/containers/container5";
import Section6 from "./components/Section6";
import section2_bg from './components/section2-bg.png';
import section7_bg from './components/section7-bg.png';
import section8_bg from './components/section8-bg.png';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';


const App = () => {

  const theme = useTheme();
  const miniScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <div className="font-[poppins]">
      <Navbar />
      <div style={{ backgroundImage: `url(${section2_bg})`, backgroundPositionX: '0%', backgroundPositionY: `100%`, backgroundRepeat: 'no-repeat' }}>
        <div className="mx-3 md:px-20">
          <HeroSection miniScreen={miniScreen} />
          <Section3 miniScreen={miniScreen} />
          <Section4 miniScreen={miniScreen} />
        </div>
      </div>

      <Section5 miniScreen={miniScreen} />
      <Section6 miniScreen={miniScreen} />

      <div style={{position: 'relative' ,marginTop: '16%', backgroundImage: `url(${section7_bg})`, backgroundPositionX: '100%', backgroundPositionY: '50%', backgroundRepeat: 'no-repeat', backgroundSize: '140% 1100px' }}>
        <div className="mx-3 md:mx-20">
          <Section7 miniScreen={miniScreen} />
          <div className="flex justify-center my-10">
            <div className="bg-[#8066FF] text-white py-3 px-8 w-fit text-3xl font-semibold rounded-xl">Get Started</div>
          </div>
        </div>
      </div>

      <div className="-mt-32" style={{ backgroundImage: `url(${section8_bg})`, backgroundPositionX: '40%', backgroundPositionY: '30%', backgroundRepeat: 'no-repeat', backgroundSize: '180% 650px' }}>
        <div className="py-20 mx-3 md:mx-20">
          <Container5 miniScreen={miniScreen} />
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default App;
