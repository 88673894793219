import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';


export default function Container5({ miniScreen }) {

  return (
    <div className='flex justify-center'>
      <div className='2xl:w-[1500px] 3xl:w-[1600px] '>
        <Box sx={{ paddingY: '100px', marginX: "3%", backgroundColor: "#8066FF", borderRadius: "30px", marginTop: "70px", textAlign: "center" }}>
          <div className='flex flex-col gap-5'>
            <div className='text-white text-2xl font-extrabold md:hidden flex flex-col'>
              Start making the most of your Sales & Distribution channels with <span className='flex text-center justify-center'><img src="images/g.png" alt="" className='h-7 ml-2 -mr-1' />apicorn</span>
            </div>

            <div className='md:justify-center text-white text-3xl xl:text-5xl 2xl:text-6xl font-extrabold leading-loose hidden md:flex px-2'>
              <div>
                <div className=''>
                  Start making the most of your Sales &
                </div>
                <div className='flex pt-4'>
                  Distribution channels with <span className='flex justify-center'><img src="images/g.png" alt="" className='h-14 ml-2 -mr-2' />apicorn</span>
                </div>
              </div>

            </div>
            
            <p className='text-white mx-3 md:mx-0 pt-6'>
              COJIANT empowers clients to achieve their maximum potential at each stage of their performance management<br></br>
              initiatives – from planning and budgeting to measuring, monitoring ,reporting and analysis.
            </p>
            <div className='flex justify-center mt-4'>
              <div className='text-white font-semibold mt-5 text-xl border-4 border-white rounded-xl cursor-pointer px-6 py-4 w-fit'>Schedule a meeting</div>
            </div>
          </div>
        </Box>
      </div>
    </div>
  );
}